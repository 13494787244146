// eslint-disable-next-line import/prefer-default-export
export const tableBiodata = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'name',
    label: 'Nama Training Center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'capacity',
    label: 'Kapasitas',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'address',
    label: 'Lokasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      width: '350px',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]
